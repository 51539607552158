import { graphql } from "gatsby";
import HomeLoans from "../../templates/homeLoans";

const HomeLoansPage = ({
	data: {
		craft: { entry },
	},
}) => <HomeLoans entry={entry} />;

export const pageQuery = graphql`
	query HomeLoansPage {
		craft {
			entry(slug: "home-loans", section: "homeLoans") {
				... on CraftCMS_homeLoans_homeLoans_Entry {
					id
					heroTitle
					heroText
				}
			}
		}
	}
`;

export default HomeLoansPage;
